import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store,persistor } from "./redux/store";
import Error from "./utils/error"
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <Error>
    <App />
    </Error>
  </Provider>
);
persistor.persist();

reportWebVitals();
