import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-cube';
import 'swiper/css/effect-cards';
import 'swiper/css/effect-flip';
import 'swiper/css/effect-coverflow';
import { useSelector } from "react-redux";
import { useEffect, useState, useRef, useCallback } from "react";
import { Navigation, Pagination, Autoplay, EffectFade, EffectCube, EffectCoverflow, EffectCards, EffectFlip } from 'swiper/modules';
import { FaVolumeMute, FaVolumeUp, FaExpand, FaCompress } from 'react-icons/fa';

async function cacheVideo(url) {
  try {
    console.log(`Attempting to cache video: ${url}`);
    const cache = await caches.open('video-cache');
    const cachedResponse = await cache.match(url);
    if (cachedResponse) {
      console.log(`Cache hit for ${url}`);
      return URL.createObjectURL(await cachedResponse.blob());
    }

    console.log(`Cache miss for ${url}, fetching...`);
    const response = await fetch(url);
    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

    const buffer = await response.arrayBuffer();
    const fullResponse = new Response(buffer, { status: 200, statusText: 'OK' });
    await cache.put(url, fullResponse.clone());
    return URL.createObjectURL(fullResponse.blob());
  } catch (error) {
    console.error(`Error caching video ${url}:`, error);
    return url; // Fallback to original URL if caching fails
  }
}

function Player() {
  const data = useSelector((state) => state.user);
  const [playlistContent, setPlaylistContent] = useState(data?.source[0]?.source?.content || []);
  const [currentEffect, setCurrentEffect] = useState('slide');
  const [isMuted, setIsMuted] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [controlsVisible, setControlsVisible] = useState(false);
  const [slideDurations, setSlideDurations] = useState({});
  const [cachedVideoUrls, setCachedVideoUrls] = useState({});
  const [loading, setLoading] = useState(false);
  const swiperRefs = useRef({});
  const playerRef = useRef(null);
  const isFullscreenChange = useRef(false);
  const hideControlsTimeout = useRef(null);

  useEffect(() => {
    setPlaylistContent(data?.source[0]?.source?.content || []);
  }, [data.source]);

  const preloadVideos = async (content) => {
    const videos = {};
    const cachePromises = [];
    console.log("Starting to preload videos...");
    (content || []).forEach((res, index) => {
      const playlist = res?.playlist || [];
      for (const res1 of playlist) {
        if (res1.type === 'video') {
          console.log(`Queueing video for caching: ${res1.meta.video_id}`);
          const cachePromise = cacheVideo(`https://vz-d99c6c4e-749.b-cdn.net/${res1.meta.video_id}/original`).then(cachedUrl => {
            videos[res1.meta.video_id] = cachedUrl;
            console.log(`Cached video: ${res1.meta.video_id}`);
          }).catch(error => {
            console.error(`Error caching video ${res1.meta.video_id}:`, error);
          });
          cachePromises.push(cachePromise);
        }
      }
    });
    await Promise.all(cachePromises);
    setCachedVideoUrls(videos);
    setLoading(false);
    console.log("Finished preloading videos.");
  };

  useEffect(() => {
    setLoading(true);
    preloadVideos(playlistContent);
  }, [playlistContent]);

  useEffect(() => {
    const durations = {};
    for (const key in playlistContent) {
      durations[key] = playlistContent[key].playlist.map(item => item?.meta?.duration || 5);
    }
    setSlideDurations(durations);
  }, [playlistContent]);

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
    for (const key in swiperRefs.current) {
      const swiperInstance = swiperRefs.current[key];
      const activeSlideVideo = swiperInstance.slides[swiperInstance.realIndex].querySelector('video');
      if (activeSlideVideo) {
        activeSlideVideo.muted = !isMuted;
        if (!isMuted) {
          activeSlideVideo.play().catch(error => {
            console.error("Error playing video:", error);
          });
        }
      }
    }
  };

  const handleFullScreenToggle = () => {
    isFullscreenChange.current = true;
    if (!isFullscreen) {
      if (playerRef.current.requestFullscreen) {
        playerRef.current.requestFullscreen();
      } else if (playerRef.current.mozRequestFullScreen) { /* Firefox */
        playerRef.current.mozRequestFullScreen();
      } else if (playerRef.current.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        playerRef.current.webkitRequestFullscreen();
      } else if (playerRef.current.msRequestFullscreen) { /* IE/Edge */
        playerRef.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
    setTimeout(() => {
      isFullscreenChange.current = false;
    }, 1000);
  };

  const handleMouseEnter = () => {
    setControlsVisible(true);
    clearTimeout(hideControlsTimeout.current);
  };

  const handleMouseLeave = () => {
    hideControlsTimeout.current = setTimeout(() => {
      setControlsVisible(false);
    }, 2500);
  };

  const handleMouseMove = () => {
    setControlsVisible(true);
    clearTimeout(hideControlsTimeout.current);
    hideControlsTimeout.current = setTimeout(() => {
      setControlsVisible(false);
    }, 2500);
  };

  const handleSlideChangeTransitionEnd = useCallback((swiperInstance) => {
    swiperInstance.slides.forEach(slide => {
      const video = slide.querySelector('video');
      if (slide.classList.contains('swiper-slide-active') && video) {
        video.play().catch(error => {
          console.error("Error playing video:", error);
        });
      } else if (video) {
        video.pause();
        video.currentTime = 0;
      }
    });
  }, []);

  const updateAutoplayDelay = useCallback((swiper, index) => {
    const currentIndex = swiper.realIndex;
    const slideDuration = (slideDurations[index]?.[currentIndex] || 5) * 1000;

    if (swiper.params.autoplay) {
      swiper.params.autoplay.delay = slideDuration;
      swiper.autoplay.stop(); // Durdurup tekrar başlatmak daha güvenli olabilir.
      swiper.autoplay.start();
    } else {
      swiper.params.autoplay = { delay: slideDuration, disableOnInteraction: false };
      swiper.autoplay.start();
    }

    swiper.slides.forEach(slide => {
      const video = slide.querySelector('video');
      if (slide.classList.contains('swiper-slide-active') && video) {
        video.play().catch(error => {
          console.error("Error playing video:", error);
        });
      } else if (video) {
        video.pause();
        video.currentTime = 0;
      }
    });
  }, [slideDurations]);

  const handleInit = useCallback((swiper, index) => {
    updateAutoplayDelay(swiper, index);
    handleSlideChangeTransitionEnd(swiper);
  }, [updateAutoplayDelay, handleSlideChangeTransitionEnd]);

  const handleFullScreenChange = useCallback(() => {
    if (isFullscreenChange.current) return;
    if (document.fullscreenElement) {
      setIsFullscreen(true);
      for (const key in swiperRefs.current) {
        const swiperInstance = swiperRefs.current[key];
        updateAutoplayDelay(swiperInstance, key);
      }
    } else {
      setIsFullscreen(false);
      for (const key in swiperRefs.current) {
        const swiperInstance = swiperRefs.current[key];
        updateAutoplayDelay(swiperInstance, key);
      }
    }
  }, [updateAutoplayDelay]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('MSFullscreenChange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
    };
  }, [handleFullScreenChange]);

  if (loading) {
    return (
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '24px',
        fontWeight: 'bold',
        textAlign: 'center'
      }}>
        Loading...
        <div style={{
          border: '16px solid #f3f3f3',
          borderRadius: '50%',
          borderTop: '16px solid #3498db',
          width: '120px',
          height: '120px',
          animation: 'spin 2s linear infinite',
          marginTop: '20px'
        }}></div>
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }

  return (
    <div
      ref={playerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      style={{ position: 'relative' }}
    >
      <div style={{ position: 'absolute', top: 10, right: 10, zIndex: 1000, display: controlsVisible ? 'flex' : 'none', gap: '10px' }}>
        <button onClick={handleMuteToggle} style={{ background: 'transparent', border: 'none', cursor: 'pointer', color: 'white' }}>
          {isMuted ? <FaVolumeMute size={30} /> : <FaVolumeUp size={30} />}
        </button>
        <button onClick={handleFullScreenToggle} style={{ background: 'transparent', border: 'none', cursor: 'pointer', color: 'white' }}>
          {isFullscreen ? <FaCompress size={30} /> : <FaExpand size={30} />}
        </button>
      </div>
      <div
        className={`d-flex ${
          data.source[0].source?.layout?.properties?.container === "flex-col"
            ? "flex-column"
            : data.source[0].source?.layout?.properties?.container
        } slider-container`}
      >
        {data.source[0].source?.layout?.properties?.boxes
          ? data.source[0].source?.layout?.properties?.boxes.map((resx, index) => {
              const playlist = playlistContent[index]?.playlist;
              
              return (
                <div
                  key={index}
                  style={{ cursor: "pointer" }}
                  id={`container-${index}`}
                  className={`stretchy-wrapper ${resx.classes} ${resx.classes}-extend`}
                >
                  {playlist && (
                    <Swiper
                      key={`${index}-${playlist.length}`} 
                      onSwiper={(swiper) => {
                        swiperRefs.current[index] = swiper;
                        updateAutoplayDelay(swiper, index);
                      }}
                      onInit={(swiper) => handleInit(swiper, index)}
                      style={{ height: "-webkit-fill-available" }}
                      modules={[Autoplay, EffectFade, EffectCube, EffectCards, EffectCoverflow, EffectFlip]}
                      effect={currentEffect}
                      loop={true}
                      navigation={false}
                      pagination={false}
                      autoplay={{
                     
                        disableOnInteraction: false,
                      }}
                      onSlideChangeTransitionEnd={(swiper) => handleSlideChangeTransitionEnd(swiper)} 
                      onSlideChange={(swiper) => updateAutoplayDelay(swiper, index)}
                    >
                      {playlist.map((res, idx) => {
                        const isVideo = res?.type === "video";
                        const imageUrl = !isVideo
                          ? `https://${res?.domain}/${res?.path}/${res?.file}`
                          : `https://vz-d99c6c4e-749.b-cdn.net/${res?.meta?.video_id}/preview.webp`;

                        return (
                          <SwiperSlide
                            key={idx}
                            style={{ height: "-webkit-fill-available" }}
                          >
                            <div
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: 2
                              }}
                            >
                              {res?.type === 'image' && (
                                <img
                                  style={{
                                    objectFit: `${res?.meta.objectFit}`,
                                    height: '-webkit-fill-available'
                                  }}
                                  src={imageUrl}
                                  width={'100%'}
                                />
                              )}
                              {res?.type === 'video' && (
                                <video
                                  src={cachedVideoUrls[res?.meta.video_id]}
                                
                                  style={{
                                    flex: 1,
                                    width: '100%',
                                    height: '-webkit-fill-available',
                                    objectFit: `${res?.meta.objectFit || 'cover'}`
                                  }}
                                
                                  muted={isMuted}
                                  playsInline={true}
                                  allowFullScreen={true}
                                  loop={playlist.length === 1}
                                  onPlay={() => console.log(`Playing video: ${res?.meta.video_id}`)}
                                  onPause={() => console.log(`Paused video: ${res?.meta.video_id}`)}
                                  onLoadedData={() => console.log(`Loaded video data: ${res?.meta.video_id}`)}
                                
                                > </video>
                              )}
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  )}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}

export default Player;
