import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { ClientJS } from "clientjs";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config/config.json";
import { useDispatch, useSelector } from "react-redux";
import { setSerial } from "../../redux/userSlice";
import logo from "../../white_black_logo.png";
import CryptoJS from "crypto-js";

function DeviceNumber() {
  const data = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const client = new ClientJS();
  const ua = client.getBrowserData().ua;
  const screen = String(client.getAvailableResolution());
  const matches = screen.match(/(\d+)x(\d+)/);

  const getDeviceType = (userAgent) => {
    if (/mobile/i.test(userAgent)) {
      return "Mobile";
    } else if (/tablet/i.test(userAgent)) {
      return "Tablet";
    } else {
      return "Desktop";
    }
  };

  const getConnectionType = () => {
    if (navigator.connection) {
      return navigator.connection.effectiveType;
    }
    return "unknown";
  };

  let screenWidth, screenHeight;
  if (matches) {
    screenWidth = parseInt(matches[1]);
    screenHeight = parseInt(matches[2]);
  }

  const meta = {
    screen_width: screenWidth,
    screen_height: screenHeight,
    os: client.getOS(),
    browser: client.getBrowser(),
    device: getDeviceType(ua),
    user_agent: ua,
    platform: navigator.platform,
    language: navigator.language,
    connection_type: getConnectionType(),
    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    java_enabled: navigator.javaEnabled(),
    cookie_enabled: navigator.cookieEnabled,
  };

  const createFingerprint = () => {
    const fingerprintString = `${meta.browser}-${meta.device}-${meta.user_agent}-${meta.platform}-${meta.language}-${meta.connection_type}-${meta.time_zone}-${meta.java_enabled}-${meta.cookie_enabled}`;
    return CryptoJS.MD5(btoa(fingerprintString)).toString();
  };

  const [fingerprint, setFingerprint] = useState(null);

  useEffect(() => {
    const storedFingerprint = localStorage.getItem("fingerprint");
    if (!storedFingerprint) {
      const newFingerprint = createFingerprint();
      localStorage.setItem("fingerprint", newFingerprint);
      setFingerprint(newFingerprint);
    } else {
      setFingerprint(storedFingerprint);
    }
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        "https://api.maiasignage.com/api/v1/device/pre_create",
        {
          fingerprint: String(fingerprint),
          meta: meta,
        }
      );

      const { serial } = response.data;

      if (serial) {
        console.log("Serial Number:", serial);
        dispatch(setSerial(serial));
      }
    } catch (error) {
      toast.error("Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.");
    }
  };

  useEffect(() => {
    if (data.serial === "none" && fingerprint) {
      handleSubmit();
    }
  }, [data.serial, fingerprint]);

  return (
    <>
      <div className="row justify-content-center align-items-center device-number-container">
        <div className="d-flex justify-content-center align-items-center">
          <p className="pairing-code">
            {data?.serial !== "none" ? data?.serial : null}
          </p>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <span className="pairing-line-1">app.maiasignage.com</span>
          <span className="pairing-line-2">
            To pair the device and start using it, please visit
          </span>
          <img className="device-number-logo" src={logo} alt="logo" />
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default DeviceNumber;
