import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setSourceData } from "./redux/userSlice";
import Player from "./components/content-player/player";
import { io } from "socket.io-client";
import Device from "./components/device-number/device-number";
import axios from "axios";
import config from "./config/config.json";

function App() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user);
  const [message, setMessage] = useState("");
  const intervalRef = useRef(null);

  const sendStatus = (status, socket) => {
    try {
      socket.emit("call", "v1.device.status", {
        token: data.serial, serial: data.serial,
        state: status
      }, function (err, res) {
        if (err) {
          console.log("error", err);
        } else {
          console.log("status-sent", res);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  const checkSerialSocket = (status, socket) => {
    try {
      socket.emit("call", "v1.device.check_serial", {
        token: data.serial, serial: data.serial,
        state: status
      }, function (err, res) {
        if (err) {
          console.log("error", err);
        } else {
          console.log("status-sent", res);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  const checkSerial = async () => {
    try {
      const response = await axios.post(config.api_base + "/v1/device/check_serial", {
        serial: data.serial,
      });
      setMessage(response?.data?.message);
    } catch (error) {
      console.error("Serial check failed", error);
    }
  };

  useEffect(() => {
    if (message !== "Used Serial Number") {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      intervalRef.current = setInterval(() => {
        checkSerial();
      }, 10000); // 10000 ms = 10 seconds
    }

    // Cleanup function to clear interval when component unmounts
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [data.serial, message]);

  const [prevArgs, setPrevArgs] = useState(null);

  useEffect(() => {
    let socket;
    if (message !== "" && message === "Used Serial Number") {
      if (data?.serial !== "none") {
        console.log("Serial Number:", data.serial);
        socket = io("https://ws.maiasignage.com", {
          reconnectionDelayMax: 10000,
          transports: ["websocket"],
          auth: {
            token: data?.serial,
          },
          query: {
            token: data?.serial,
          },
          timeout: 30000, // Increased timeout
          reconnectionAttempts: Infinity,
          reconnectionDelay: 5000,
        });

        socket.on("connect", () => {
          console.log("Connected with:", socket.id);
          socket.emit("ping");

          intervalRef.current = setInterval(() => {
            sendStatus("online", socket);
          }, 5 * 1000);
        });

        socket.on("pong", () => {
       /*    console.log("Pong received from server"); */
        });

        socket.on("connect_error", (err) => {
          console.error("Connection error:", err.message);
          console.error("Error details:", err);
        });

        socket.on("disconnect", (reason) => {
          console.log("Disconnected", socket.id); // undefined
          console.log("Disconnect reason:", reason); // Log disconnect reason
          sendStatus("offline", socket);
          if (reason === "io server disconnect") {
            // Server disconnected, force reconnect
            socket.connect();
          } else if (reason === "transport close") {
            console.log("Transport closed");
          } else if (reason === "ping timeout") {
            console.log("Ping timeout");
          }
        });

        socket.onAny((eventName, ...args) => {
          if (eventName === "device") {
            console.log(eventName, args);

            const newArgsString = JSON.stringify(args);
            const prevArgsString = JSON.stringify(prevArgs);
            const dataString = JSON.stringify(data.source);

            if (newArgsString !== prevArgsString && newArgsString !== dataString) {
              dispatch(setSourceData(args));
              setPrevArgs(args);
            }
          }
        });
      }
    }
    return () => {
      if (socket) {
        socket.disconnect();
        console.log("Socket disconnected");
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [message, data?.serial, dispatch, prevArgs, data.source]);

  return (
    <div>
      {data?.source[0]?.source ? <Player /> : <Device />}
    </div>
  );
}

export default App;
